import { Link } from "gatsby";
import * as React from "react";

const Header: React.FunctionComponent = () => (
    <header className="bg-gray-200">
        <div className="container mx-auto py-4 space-x-12 flex justify-center items-center">
            <div className="flex justify-center space-x-12 items-center">
                <h1 className="text-3xl mb-0 brand-font">Rose Lange Art</h1>

                {/* <nav className="space-x-4">
                    <Link to="/store" activeClassName="font-semibold">
                        Store
                    </Link>

                    <Link to="/lessons" activeClassName="font-semibold">
                        Lessons
                    </Link>
                </nav> */}
            </div>

            {/* <div>
                <Link to="/cart">Cart</Link>
            </div> */}
        </div>
    </header>
);

export default Header;
