import * as React from "react";

const Footer: React.FunctionComponent = () => (
    <footer className="bg-gray-600 text-gray-200">
        <div className="container mx-auto py-6 px-8">
            &copy; 2021&ndash;present Rose Lange
        </div>
    </footer>
);

export default Footer;
