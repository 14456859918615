import * as React from "react";
import { Helmet } from "react-helmet";

import Footer from "../core/footer";
import Header from "../core/header";

interface BaseLayoutProps {
    docTitle?: string;
}

const BaseLayout: React.FunctionComponent<BaseLayoutProps> = ({
    children,
    docTitle = "Rose Lange Art"
}) => (
    <div className="bg-gray-100" style={{ minHeight: "100vh" }}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{docTitle}</title>
            <link rel="canonical" href="https://roselange.art" />
        </Helmet>
        <Header />

        <div>{children}</div>

        <Footer />
    </div>
);

export default BaseLayout;
